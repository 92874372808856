<template>
  <div class="wrapper">
    <div class="section">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto" >
            <login-card header-color="primary">
              <h4 slot="title" class="card-title">CMS Easy Pay | Admin</h4>
              <div slot="buttons" class="md-caption card-title" style="font-weight: normal; cursor: pointer;" @click="onGoBack">
                Zurück zur Übersicht
              </div>

              <p slot="description" class="description">{{ id ? 'Bearbeiten' : 'Erstellen' }}  Sie einen Event</p>


              <md-field slot="inputs">
                <label>Name</label>
                <md-input v-model="name"></md-input>
              </md-field>
              <md-field slot="inputs">
                <label>Beschreibung</label>
                <md-textarea v-model="description"></md-textarea>
              </md-field>
              <md-field slot="inputs">
                <label>Datum</label>
                <md-input v-model="event_date" type="date" lang="de-CH"></md-input>
              </md-field>
              <div slot="inputs">
                  <div style="color:#AAA">Rechnungen</div>
                  <table style="width: 100%">
                    <tr>
                      <td>Datum</td>
                      <td>Name</td>
                      <td>Betrag</td>
                      <td>Status</td>
                      <td style="width:0px"></td>
                    </tr>

                    <tr v-for="payment in payments" :key="payment.id">
                      <td @click="gotoPayment(payment.id)">{{ payment.date | moment('DD.MM.YYYY HH:mm') }}</td>
                      <td @click="gotoPayment(payment.id)">{{ payment.name }}</td>
                      <td @click="gotoPayment(payment.id)">{{ payment.amount }} CHF</td>
                      <td @click="gotoPayment(payment.id)"><payment-status :payment="payment" /></td>
                      <td @click="deletePayment(payment.id)">
                        <md-icon>delete_outline</md-icon>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="5">
                        <div class="md-button" @click="onAddPayment" v-if="id" style="text-align:center;width:100%">
                          <md-icon>add</md-icon>
                        </div>
                      </td>
                    </tr>
                  </table>
              </div>

              <md-field slot="inputs" v-if="id">
                <div style="width: 100%; margin-bottom: 7px; display: flex; flex-direction: column; justify-content: center;">
                  <md-button @click="onShare">Schnellanmelde Code</md-button>
                </div>
              </md-field>

            </login-card>
          </div>
        </div>
      </div>
    </div>
    <md-button class="md-fab md-mini md-btn-fab md-just-icon md-primary md-fab-bottom-right" style="position: fixed; border-radius: 50%;" @click="onSave" v-if="!isLoading">
      <md-icon>save</md-icon>
    </md-button>
    <md-button class="md-fab md-mini md-btn-fab md-just-icon md-primary md-fab-bottom-right" style="position: fixed; border-radius: 50%;" v-else="">
      <md-progress-spinner md-mode="indeterminate" :md-diameter="30">
        <md-icon>hourglass_empty</md-icon>
      </md-progress-spinner>
    </md-button>
  </div>
</template>

<script>
import LoginCard from '@/components/LoginCard';
import PaymentStatus from '@/components/PaymentStatus';
import AdminService from '@/services/AdminService';

export default {
  components: {
    LoginCard,
    PaymentStatus
  },
  data() {
    return {
      id: '',
      event_date: '',
      name: '',
      payments: [],
      event_key: '',
      isLoading: false,
      description: ''
    };
  },
  methods: {
    setData(code) {
      if(!AdminService.token)
        this.$router.push('/')
      else {
        if(code == 'new') {
          this.id = ''
          this.name = ''
          this.event_date = new Date().toISOString().split('T')[0]
          this.payments = []
          this.event_key = ''
          this.description = ''
        } else {
          AdminService.getEvent(code).then(data => {
            if(!data.id)
              this.$router.push('/admin/events')
            else {
              this.id = code
              this.name = data.name
              this.event_date = data.event_date
              this.payments = data.payments
              this.description = data.description
            }
          })
        }
      }
    },
    gotoPayment(id) {
      this.$router.push(`/admin/payment/${id}`)
    },
    deletePayment(id) {
      if(confirm('Wollen Sie diese Zahlung wirklich löschen')) {
        AdminService.deletePayment(id)
          .then(data => data.success && (this.payments = this.payments.filter(i => i.id != id)))
      }
      return true;
    },
    onGoBack() {
      this.$router.back()
    },
    onSave() {
      if(this.name && this.event_date) {
        this.isLoading = true
        AdminService.saveEvent(this.id, this.name, this.event_date, this.description)
          .then(data => {
            this.isLoading = false
            this.id = data.id
          })
      }
    },
    onShare() {
      let pk = btoa(this.id).replace(/\//g, '$')
      let n = btoa(this.name).replace(/\//g, '$')
      let pub = btoa(AdminService.keypair.publicKey).replace(/\//g, '$')
      this.$router.push(`/admin/payment/qr?data=`+encodeURIComponent(`TEMPLATE|EVENT_${n}_${pk}_${pub}`))
    },
    onAddPayment() {
      this.$router.push(`/admin/payment/new?event=${this.id}`)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(decodeURIComponent(to.params.code)))
  },
  beforeRouteUpdate(to, from, next) {
    this.setData(to.params.id)
    next()
  },
  props: {
  },
  computed: {
  }
};
</script>

<style lang="css">
  .md-card { width: 100%; }
</style>
