<template>
  <div class="wrapper">
    <div class="section">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto" >
            <login-card header-color="primary">
              <h4 slot="title" class="card-title">CMS Easy Pay | Admin</h4>
              <div slot="buttons" class="md-caption card-title" style="font-weight: normal; cursor: pointer;" @click="onGoBack">
                Zurück
              </div>

              <div slot="description" style="text-align: center;">
                <p class="description">{{ id ? 'Bearbeiten' : 'Erstellen' }}  Sie eine Rechnung</p>
                <payment-status v-if="status" :payment="status"></payment-status>
                <span style="margin-left: 7px; cursor: pointer;" @click="reloadPayment"><md-icon>refresh</md-icon></span> 
              </div>

              <md-field slot="inputs" v-if="id && status < 100">
                <div style="width: 100%; margin-bottom: 7px; display: flex; flex-direction: column; justify-content: center;">
                  <qrcode :value="code" :options="{ width: 200 }" style="margin: auto;"></qrcode>
                </div>
                <div style="width: 100%; margin-bottom: 7px; display: flex; justify-content: center;">
                  <md-button style="flex: 1 1 0px" @click="onShare">Teilen</md-button>
                  <md-button style="flex: 1 1 0px" @click="cashpay">Bar bezahlt.</md-button>
                </div>
              </md-field>

              <md-field slot="inputs">
                <label>Name</label>
                <md-input v-model="name"></md-input>
              </md-field>
              <md-field slot="inputs">
                <label>Beschreibung</label>
                <md-textarea v-model="description"></md-textarea>
              </md-field>
              <md-field slot="inputs">
                <label>Verwendung</label>
                <md-input v-model="usage" :disabled="!!event_uid"></md-input>
              </md-field>
              <md-field slot="inputs">
                <label>Betrag</label>
                <md-input v-model="amount"></md-input>
                <span class="md-suffix">CHF</span>
              </md-field>


            </login-card>
          </div>
        </div>
      </div>
    </div>
    <md-button class="md-fab md-mini md-btn-fab md-just-icon md-primary md-fab-bottom-right" style="position: fixed; border-radius: 50%;" @click="onSave" v-if="!isLoading">
      <md-icon>save</md-icon>
    </md-button>
    <md-button class="md-fab md-mini md-btn-fab md-just-icon md-primary md-fab-bottom-right" style="position: fixed; border-radius: 50%;" v-else>
      <md-progress-spinner md-mode="indeterminate" :md-diameter="30">
        <md-icon>hourglass_empty</md-icon>
      </md-progress-spinner>
    </md-button>
  </div>
</template>

<script>
import LoginCard from '@/components/LoginCard';
import PaymentStatus from '@/components/PaymentStatus';
import AdminService from '@/services/AdminService';

export default {
  components: {
    LoginCard,
    PaymentStatus
  },
  data() {
    return {
      id: '',
      name: '',
      usage: '',
      amount: '',
      payment_key: '',
      status: null,
      isLoading: false,
      event_uid: '',
      description: '',
      date: ''
    };
  },
  methods: {
    cashpay() {
      if(confirm("Wohlen Sie die Zahlung wirklich als bezahlt erfassen?")){
        AdminService.cashPayment(this.id).then(r => {
          this.status = r.status
          this.reloadPayment();
        })
      
      }
      

    },
    setData(code,event_uid) {
      if(!AdminService.token)
        this.$router.push('/')
      else {
        if(code == 'new') {
          this.id = ''
          this.payment_key = ''
          this.name = ''
          this.usage = ''
          this.amount = ''
          this.status = {}
          if(event_uid)
            AdminService.getEvent(event_uid).then(data => {
              if(data) {
                this.event_uid = event_uid
                this.usage = data.name
                this.description = data.description
                this.date = data.event_date
              }
            })
        } else {
          AdminService.getPayment(code).then(data => {
            if(!data.key)
              this.$router.push('/admin/payments')
            else {
              this.id = code
              this.payment_key = data.key
              this.name = data.name
              this.usage = data.usage
              this.amount = data.amount
              this.event_uid = data.event_uid
              this.description = data.description
              this.date = data.date
              this.status = {
                status: data.status,
                payed_at: data.payed_at,
                payment_method: data.payment_method
              } 
            }
          })
        }
      }
    },
    onGoBack() {
      this.$router.back()
    },
    onSave() {
      this.isLoading = true

      if(!this.id || !this.payment_key) 
        this.payment_key = AdminService.generateKey()

      AdminService.savePayment(this.id, this.name, this.usage, this.amount, this.description, this.date, this.payment_key, this.event_uid)
        .then(data => {
          this.id = data.id
          this.status = null 
          this.isLoading = false
        })
    },
    onShare() {
      if (navigator.share) {
        navigator.share({
          title: 'pay.cmsevents.ch',
          text: 'Zahle jetzt auf pay.cmsevents.ch',
          url: this.code,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error))
      } else {
        prompt('Copy & paste', this.code)
      }
    },
    reloadPayment() {
      if(this.id)
        this.setData(this.id, this.event_uid)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(to.params.code,to.query.event))
  },
  beforeRouteUpdate(to, from, next) {
    this.setData(to.params.id,to.query.event)
    next()
  },
  props: {
  },
  computed: {
    code() {
      let pk = this.payment_key.replace(/\//g,'$');
      return `https://pay.cmsevents.ch/#/pay/${encodeURIComponent(`${this.id}|${pk}`)}`
    },
  }
};
</script>

<style lang="css">
  .md-card { width: 100%; }

  .md-field.md-theme-default{
    display: block;
  }

  .md-has-textarea {
    display: flex !important;
  }

  .md-has-value {
    display: flex !important;
  }



</style>
