<template>
  <div class="wrapper">
    <div class="section">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto" >
            <login-card header-color="primary">
              <h4 slot="title" class="card-title">CMS Easy Pay | Admin</h4>

              <p slot="description" class="description">
                <span @click="navigateToPayments">Zahlungen</span> | <b>Events</b> | <span @click="logout">Logout</span>
              </p>

              <md-list slot="content">
                <div v-if="isLoading">Daten werden geladen...</div>
                <md-list-item :to="`/admin/event/${event.id}`" v-for="event in events" :key="event.id">
                  <div class="md-list-item-text">
                     <div class="payment-line">{{ event.name }}</div>
                      <span>{{ event.event_date }}</span>
                  </div>
                  <div class="md-list-action" @click.prevent="deleteEvent(event.id)">
                    <md-icon>delete_outline</md-icon>
                  </div>
                </md-list-item>
              </md-list>
              <div slot="footer">
                <pagination
                  v-model="selectedPage"
                  :page-count="pageCount">
                </pagination>
              </div>
            </login-card>
          </div>
        </div>
      </div>
    </div>
    <md-button class="md-fab md-mini md-btn-fab md-just-icon md-primary md-fab-bottom-right" style="position: fixed; border-radius: 50%;" @click="onAdd">
      <md-icon>add</md-icon>
    </md-button>
  </div>
</template>

<script>
import LoginCard from '@/components/LoginCard';
import Pagination from '@/components/Pagination';
import AdminService from '@/services/AdminService';


export default {
  components: {
    LoginCard,
    Pagination
  },
  data() {
    return {
      events: [],
      selectedPage: 1,
      pageCount: 0,
      isLoading: false,
    };
  },
  methods: {
    loadData() {
      if(!AdminService.token)
        this.$router.push('/')
      else {
        this.isLoading = true
        AdminService.getAllEvents(this.selectedPage || 1)
          .then(data => {
            this.events = data.data
            this.pageCount = Math.ceil(data.total / data.per_page)
            this.isLoading = false
          })
      }
    },
    onAdd() {
      this.$router.push({ path: '/admin/event/new' })
    },
    navigateToPayments() {
      this.$router.push({ path: '/admin/payments' })
    },
    deleteEvent(id) {
      if(confirm('Wollen Sie diesen Event wirklich löschen')) {
        AdminService.deleteEvent(id)
          .then(data => data.success && this.loadData())
      }
    },
    logout() {
      AdminService.logout()
      this.$router.push({ path: '/' })
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.loadData())
  },
  beforeRouteUpdate(to, from, next) {
    this.loadData()
    next()
  },
  props: {
  },
  watch: {
    selectedPage: function() {
      this.loadData()
    }
  },
  computed: {
  }
};
</script>

<style lang="css">
.md-card { width: 100%; }
.md-list-item a, .md-list-item a .md-ripple {
  border-radius: 0px;
}
.payment-line .amount {
  float: right;
  width: auto;
}
.list-header {
  padding: 4px 16px;
  font-weight: 700;
}
.payment-line {
  font-size: 14px;
}
</style>
