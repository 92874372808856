<template>
  <div class="wrapper">
    <div class="section">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto" >
            <login-card header-color="primary">
              <h4 slot="title" class="card-title">CMS Easy Pay | Admin</h4>


              <p slot="description" class="description">
                  <b>Zahlungen</b> | <span @click="navigateToEvents">Events</span> | <span @click="logout">Logout</span>
              </p>
              <md-list class="md-triple-line" slot="content">
                <div v-if="isLoading">Daten werden geladen...</div>
                <div v-for="(day, day_idx) in payments" :key="day_idx">
                  <div class="list-header">{{ day.date | moment("DD. MMMM YYYY") }}</div>
                  <md-divider></md-divider>
                  
                  <div v-for="(payment, idx) in day.payments" :key="idx">
                    <md-list-item :to="`/admin/payment/${payment.id}`">
                      <div class="md-list-item-text">
                        <div class="payment-line">
                          <div class="amount">{{ payment.amount.toFixed(2) }} CHF</div>
                          {{ payment.name }}
                        </div>
                        <span>{{ payment.usage }}</span>
                        <payment-status :payment="payment"></payment-status>
                      </div>
                      <div class="md-list-action" @click.prevent="deletePayment(payment.id)">
                        <md-icon>delete_outline</md-icon>
                      </div>
                    </md-list-item>
                  </div>
                </div>
              </md-list>
              <div slot="footer">
                <pagination
                  v-model="selectedPage"
                  :page-count="pageCount">
                </pagination>
              </div>
            </login-card>
          </div>
        </div>
      </div>
    </div>
    <md-button class="md-fab md-mini md-btn-fab md-just-icon md-primary md-fab-bottom-right" style="position: fixed; border-radius: 50%;" @click="onAdd">
      <md-icon>add</md-icon>
    </md-button>
  </div>
</template>

<script>
import LoginCard from '@/components/LoginCard';
import Pagination from '@/components/Pagination';
import PaymentStatus from '@/components/PaymentStatus';
import AdminService from '@/services/AdminService';


export default {
  components: {
    LoginCard,
    Pagination,
    PaymentStatus
  },
  data() {
    return {
      payments: [],
      selectedPage: 1,
      pageCount: 0,
      isLoading: false,
    };
  },
  methods: {
    loadData() {
      if(!AdminService.token)
        this.$router.push('/')
      else{
        this.isLoading = true
        AdminService.getAllPayments(this.selectedPage || 1)
        .then(payments => {
          this.pageCount = Math.ceil(payments.total / payments.per_page)
          this.payments = Object.values(payments.data.reduce((a, i, idx) => {
            let d = i.date.format('YYYY-MM-DD');
            if(!a[d]) 
              a[d] = { date: i.date, payments: [] }
            a[d].payments.push({...i, idx: idx})
            return a
          }, {})).sort((a,b) => b.date - a.date)
          this.isLoading = false
        })
      }
        
        
    },
    onAdd() {
      this.$router.push({ path: '/admin/payment/new' })
    },
    navigateToEvents() {
      this.$router.push({ path: '/admin/events' })
    },
    deletePayment(id) {
      if(confirm('Wollen Sie diese Zahlung wirklich löschen')) {
        AdminService.deletePayment(id)
          .then(data => data.success && this.loadData())
      }
    },
    logout() {
      AdminService.logout()
      this.$router.push({ path: '/' })
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.loadData())
  },
  beforeRouteUpdate(to, from, next) {
    this.loadData()
    next()
  },
  props: {
  },
  watch: {
    selectedPage() {
      this.loadData()
    }
  },
  computed: {
  }
};
</script>

<style lang="css">
.md-card { width: 100%; }
.md-list-item a, .md-list-item a .md-ripple {
  border-radius: 0px;
}
.payment-line .amount {
  float: right;
  width: auto;
}
.list-header {
  padding: 4px 16px;
  font-weight: 700;
}
.payment-line {
  font-size: 14px;
}
</style>
