<template>
  <span v-if="payment.status == 100">Bezahlt am {{ payment.payed_at | moment("DD. MMMM YYYY") }} mit: {{payment.payment_method}}</span>
  <span v-else-if="payment.status == 109">Bar bezahlt am {{ payment.payed_at | moment("DD. MMMM YYYY") }}</span>
  <span v-else>Zahlung ausstehend</span>
</template>
<script>
export default {
  name: "payment-status",
  props: {
    payment: {
      type: Object,
    }
  }
};
</script>
