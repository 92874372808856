<template>
  <div class="wrapper">
    <div class="section">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-66 md-small-size-100 mx-auto" >
            <login-card header-color="primary">
              <h4 slot="title" class="card-title">CMS Easy Pay | Admin</h4>
              <div slot="buttons" class="md-caption card-title" style="font-weight: normal; cursor: pointer;" @click="onGoBack">
                Zurück
              </div>


              <div slot="inputs" style="width: 100%; margin-bottom: 7px; display: flex; flex-direction: column; justify-content: center;">
                <qrcode v-if="code" :value="code_comp" :options="{ width: 200 }" style="margin: auto;"></qrcode>
                <md-button @click="onShare">Teilen</md-button>
              </div>

              <md-field slot="inputs">
                <label>Fix Preis</label>
                <md-input v-model="amount" type="number"></md-input>
              </md-field>

            </login-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginCard from '@/components/LoginCard';
import AdminService from '@/services/AdminService';

export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      code: '',
      amount: ''
    };
  },
  methods: {
    setData(code) {
      if(!AdminService.token)
        this.$router.push('/')
      else {
        this.code = code
      }
    },
    onGoBack() {
      this.$router.back()
    },
    onShare() {
      if (navigator.share) {
        navigator.share({
          title: 'pay.cmsevents.ch',
          text: 'Zahle jetzt auf pay.cmsevents.ch',
          url: 'https://pay.cmsevents.ch/#/pay/'+encodeURIComponent(this.code + (this.amount ? '_' + this.amount : '')),
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error))
      } else {
        prompt('Copy & paste', 'https://pay.cmsevents.ch/#/pay/'+encodeURIComponent(this.code + (this.amount ? '_' + this.amount : '')))
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setData(to.query.data))
  },
  beforeRouteUpdate(to, from, next) {
    this.setData(to.query.data)
    next()
  },
  props: {
  },
  computed: {
    code_comp() {
      return 'https://pay.cmsevents.ch/#/pay/' + this.code + (this.amount ? '_' + this.amount : '');
    },
  }
};
</script>

<style lang="css">
  .md-card { width: 100%; }
</style>
